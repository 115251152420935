import store from "@/store";

export default [
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news/News'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'news',
            breadcrumb: [
                {
                    text: 'Noticias',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            const status = store.state.profile.status

            if (!status || (status && (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateUser' || role.name === 'RealstateVendor'))) next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/news/:id/details',
        name: 'news-details',
        props: true,
        component: () => import('@/views/news/NewsDetails'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'news',
            breadcrumb: [
                {
                    text: 'Noticias',
                    to: '/news'
                },
                {
                    text: 'Detalles',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'SuperAdmin' || role.name === 'NewsUser') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/news/create',
        name: 'news-create',
        component: () => import('@/views/news/NewsCreate'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'news/create',
            breadcrumb: [
                {
                    text: 'Noticias',
                    to: '/news'
                },
                {
                    text: 'Nueva noticia',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'SuperAdmin' || role.name === 'NewsUser') next()
            else next({ name: 'error-403' })
        },
    },
]
