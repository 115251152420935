import gql from 'graphql-tag'

export const FETCH_PROPERTIES_FROM_HOME = gql`
  query fetchPropertiesFromHome(
    $project_id: String
    $real_state_agency: String
    $page: Int
  ) {
    tablepropertie(
      project_id: $project_id
      real_state_agency: $real_state_agency
      page: $page
    ) {
      data {
        model
        quotation_count
        booking_request_count
        booking_request_payments
        promise_request_count
        promise_request_payment
        units_availabe
        units_count
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`

export const FETCH_PROPERTIES = gql`
  query fetchProperties(
    $project_id: String
    $identifier: String
    $model: String
    $orientation: String
    $tower: String
    $visible: Boolean
    $page: Int
  ) {
    properties(
      project_id: $project_id
      identifier_comodin: $identifier
      page: $page
      model: $model
      orientation: $orientation
      tower: $tower
      visible: $visible
    ) {
      data {
        id
        identifier
        model
        orientation
        price
        floor
        lots
        vulnerable
        sectores_medios
        bedrooms
        bathrooms
        tower
        bookeable
        promisable
        total_area
        municipal_area
        built_area
        terrace_area
        visible
        available
        crm_id
        id_crm_movendo
        created_at
        savings
        base_subsidies
        bonus_integration
        bonus_recruitment
        medias {
          id
        }
        project {
          id
          name
          description
          slug
          address
          latitude
          longitude
          type_property
          visible
          crm_id
          commune {
            id
          }
        }
        complements {
          id
          warehouse {
            id
            identifier
            price
          }
          parkinglot {
            id
            identifier
            price
          }
        }
        audits {
          id
          event
          old_values
          new_values
          created_at
          user {
            name
            last_name
          }
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`

export const FETCH_WAREHOUSES = gql`
  query fetchWarehouses(
    $project_id: String
    $identifier: String
    $tower: String
    $visible: Boolean
    $page: Int
  ) {
    warehouses(
      project_id: $project_id
      identifier_comodin: $identifier
      page: $page
      tower: $tower
      visible: $visible
    ) {
      data {
        id
        identifier
        price
        floor
        tower
        total_area
        municipal_area
        built_area
        visible
        promisable
        bookeable
        lots
        type
        available
        created_at
        project {
          id
          name
        }
        medias {
          id
        }
        complements {
          property {
            id
            identifier
            price
          }
        }
        audits {
          id
          event
          old_values
          new_values
          created_at
          user {
            name
            last_name
          }
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`

export const FETCH_PARKINGLOTS = gql`
  query fetchParkinglots(
    $project_id: String
    $identifier: String
    $tower: String
    $visible: Boolean
    $page: Int
  ) {
    parkinglots(
      project_id: $project_id
      identifier_comodin: $identifier
      page: $page
      tower: $tower
      visible: $visible
    ) {
      data {
        id
        identifier
        price
        floor
        tower
        total_area
        municipal_area
        built_area
        visible
        bookeable
        promisable
        available
        type
        lots
        created_at
        project {
          id
          name
        }
        medias {
          id
        }
        complements {
          property {
            id
            identifier
            price
          }
        }
        audits {
          id
          event
          old_values
          new_values
          created_at
          user {
            name
            last_name
          }
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`

export const PROPERTIES_EXTRA_DATA = gql`
  query propertiesandmodelsbyproject($project_id: String) {
    propertiesandmodelsbyproject(project_id: $project_id) {
      models
      towers
    }
  }
`

export const CHANGE_VISIBLE_GROUPED_PROPERTY = gql`
  mutation editVisibleGroupedProperty(
    $properties: [String]
    $visible: Boolean!
    $propertie_type: String
  ) {
    editVisibleGroupedProperty(
      properties: $properties
      visible: $visible
      propertie_type: $propertie_type
    ) {
      status
    }
  }
`

export const CHANGE_VULNERABLE_GROUPED_PROPERTY = gql`
  mutation editVulnerableGroupedProperty(
    $properties: [String]
    $vulnerable: Boolean!
    $propertie_type: String
  ) {
    editVulnerableGroupedProperty(
      properties: $properties
      vulnerable: $vulnerable
      propertie_type: $propertie_type
    ) {
      status
    }
  }
`

export const CHANGE_SECTORMEDIO_GROUPED_PROPERTY = gql`
  mutation editSectorMedioGroupedProperty(
    $properties: [String]
    $sector_medio: Boolean!
    $propertie_type: String
  ) {
    editSectorMedioGroupedProperty(
      properties: $properties
      sector_medio: $sector_medio
      propertie_type: $propertie_type
    ) {
      status
    }
  }
`

export const DELETE_PROPERTIES = gql`
  mutation deleteProperty($id: Int!) {
    removeProperty(property_id: $id) {
      id
    }
  }
`

export const CHANGE_VISIBLE_PROPERTY = gql`
  mutation editVisibleProperty($visible: Boolean!, $property_id: Int!) {
    editVisibleProperty(visible: $visible, property_id: $property_id) {
      id
      identifier
      model
      price
      floor
      bedrooms
      bathrooms
      tower
      bookeable
      promisable
      total_area
      municipal_area
      built_area
      terrace_area
      visible
      available
      created_at
      medias {
        id
      }
      project {
        id
        name
        description
        slug
        address
        latitude
        longitude
        type_property
        visible
        commune {
          id
        }
      }
      complements {
        id
        warehouse {
          id
          identifier
          price
        }
        parkinglot {
          id
          identifier
          price
        }
      }
    }
  }
`

export const CHANGE_VISIBLE_WAREHOUSE = gql`
  mutation editVisibleWarehouse($visible: Boolean!, $warehouse_id: Int!) {
    editVisibleWarehouse(visible: $visible, warehouse_id: $warehouse_id) {
      id
      identifier
      price
      floor
      tower
      total_area
      municipal_area
      built_area
      visible
      promisable
      bookeable
      available
      type
      created_at
      project {
        id
        name
      }
      medias {
        id
      }
      complements {
        property {
          id
          identifier
          price
        }
      }
    }
  }
`
export const CHANGE_VISIBLE_PARKINGLOT = gql`
  mutation editVisibleParkingLot($visible: Boolean!, $parkinglot_id: Int!) {
    editVisibleParkingLot(visible: $visible, parkinglot_id: $parkinglot_id) {
      id
      identifier
      price
      floor
      tower
      total_area
      municipal_area
      built_area
      visible
      bookeable
      promisable
      available
      type
      created_at
      project {
        id
        name
      }
      medias {
        id
      }
      complements {
        property {
          id
          identifier
          price
        }
      }
    }
  }
`

export const UPDATE_PROPERTIES_EXCEL = gql`
  mutation updatePropertiesByExcel($excel: Upload, $project_id: String) {
    updatePropertiesByExcel(excel: $excel, project_id: $project_id) {
      status
      info
      data
      validation_errors
      properties {
        id
        identifier
        model
        orientation
        price
        floor
        lots
        vulnerable
        sectores_medios
        bedrooms
        bathrooms
        tower
        bookeable
        promisable
        total_area
        municipal_area
        built_area
        terrace_area
        visible
        available
        crm_id
        created_at
        medias {
          id
        }
        project {
          id
          name
          description
          slug
          address
          latitude
          longitude
          type_property
          visible
          crm_id
          commune {
            id
          }
        }
        complements {
          id
          warehouse {
            id
            identifier
            price
          }
          parkinglot {
            id
            identifier
            price
          }
        }
      }
    }
  }
`

export const EDIT_PROPERTY_PRICE = gql`
  mutation editProperty($property_id: Int!, $price: Float!) {
    editProperty(property_id: $property_id, price: $price) {
      id
      identifier
      model
      price
      floor
      bedrooms
      bathrooms
      tower
      bookeable
      promisable
      total_area
      municipal_area
      built_area
      terrace_area
      visible
      available
      created_at
      medias {
        id
      }
      project {
        id
        name
        description
        slug
        address
        latitude
        longitude
        type_property
        visible
        commune {
          id
        }
      }
      complements {
        id
        warehouse {
          id
          identifier
          price
        }
        parkinglot {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`
export const EDIT_PROPERTY_VISIBLE = gql`
  mutation editProperty($property_id: Int!, $visible: Boolean!) {
    editProperty(property_id: $property_id, visible: $visible) {
      id
      identifier
      model
      price
      floor
      bedrooms
      bathrooms
      tower
      bookeable
      promisable
      total_area
      municipal_area
      built_area
      terrace_area
      visible
      available
      created_at
      medias {
        id
      }
      project {
        id
        name
        description
        slug
        address
        latitude
        longitude
        type_property
        visible
        commune {
          id
        }
      }
      complements {
        id
        warehouse {
          id
          identifier
          price
        }
        parkinglot {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`
export const EDIT_PROPERTY_BOOKABLE = gql`
  mutation editProperty($property_id: Int!, $bookeable: Boolean!) {
    editProperty(property_id: $property_id, bookeable: $bookeable) {
      id
      identifier
      model
      price
      floor
      bedrooms
      bathrooms
      tower
      bookeable
      promisable
      total_area
      municipal_area
      built_area
      terrace_area
      visible
      available
      created_at
      medias {
        id
      }
      project {
        id
        name
        description
        slug
        address
        latitude
        longitude
        type_property
        visible
        commune {
          id
        }
      }
      complements {
        id
        warehouse {
          id
          identifier
          price
        }
        parkinglot {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`

export const EDIT_PROPERTY_PROMISABLE = gql`
  mutation editProperty($property_id: Int!, $promisable: Boolean!) {
    editProperty(property_id: $property_id, promisable: $promisable) {
      id
      identifier
      model
      price
      floor
      bedrooms
      bathrooms
      tower
      bookeable
      promisable
      total_area
      municipal_area
      built_area
      terrace_area
      visible
      available
      created_at
      medias {
        id
      }
      project {
        id
        name
        description
        slug
        address
        latitude
        longitude
        type_property
        visible
        commune {
          id
        }
      }
      complements {
        id
        warehouse {
          id
          identifier
          price
        }
        parkinglot {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`

export const EDIT_PARLINGLOT_VISIBLE = gql`
  mutation editParkinglot($parkinglot_id: Int!, $visible: Boolean!) {
    editParkinglot(parkinglot_id: $parkinglot_id, visible: $visible) {
      id
      identifier
      price
      floor
      tower
      total_area
      municipal_area
      built_area
      visible
      bookeable
      promisable
      available
      type
      created_at
      project {
        id
        name
      }
      medias {
        id
      }
      complements {
        property {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`
export const EDIT_PARLINGLOT_BOOKABLE = gql`
  mutation editParkinglot($parkinglot_id: Int!, $bookeable: Boolean!) {
    editParkinglot(parkinglot_id: $parkinglot_id, bookeable: $bookeable) {
      id
      identifier
      price
      floor
      tower
      total_area
      municipal_area
      built_area
      visible
      bookeable
      promisable
      available
      type
      created_at
      project {
        id
        name
      }
      medias {
        id
      }
      complements {
        property {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`

export const EDIT_PARLINGLOT_PROMISABLE = gql`
  mutation editParkinglot($parkinglot_id: Int!, $promisable: Boolean!) {
    editParkinglot(parkinglot_id: $parkinglot_id, promisable: $promisable) {
      id
      identifier
      price
      floor
      tower
      total_area
      municipal_area
      built_area
      visible
      bookeable
      promisable
      available
      type
      created_at
      project {
        id
        name
      }
      medias {
        id
      }
      complements {
        property {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`

export const EDIT_WAREHOUSE_VISIBLE = gql`
  mutation editWarehouse($warehouse_id: Int!, $visible: Boolean!) {
    editWarehouse(warehouse_id: $warehouse_id, visible: $visible) {
      id
      identifier
      price
      floor
      tower
      total_area
      municipal_area
      built_area
      visible
      promisable
      bookeable
      available
      type
      created_at
      project {
        id
        name
      }
      medias {
        id
      }
      complements {
        property {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`
export const EDIT_WAREHOUSE_BOOKABLE = gql`
  mutation editWarehouse($warehouse_id: Int!, $bookeable: Boolean!) {
    editWarehouse(warehouse_id: $warehouse_id, bookeable: $bookeable) {
      id
      identifier
      price
      floor
      tower
      total_area
      municipal_area
      built_area
      visible
      promisable
      bookeable
      available
      type
      created_at
      project {
        id
        name
      }
      medias {
        id
      }
      complements {
        property {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`

export const EDIT_WAREHOUSE_PROMISABLE = gql`
  mutation editWarehouse($warehouse_id: Int!, $promisable: Boolean!) {
    editWarehouse(warehouse_id: $warehouse_id, promisable: $promisable) {
      id
      identifier
      price
      floor
      tower
      total_area
      municipal_area
      built_area
      visible
      promisable
      bookeable
      available
      type
      created_at
      project {
        id
        name
      }
      medias {
        id
      }
      complements {
        property {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`

export const EDIT_PROPERTY_ALL = gql`
  mutation editProperty(
    $property_id: Int!
    $identifier: String
    $model: String
    $tower: String
    $lots: String
    $total_area: Float
    $municipal_area: Float
    $terrace_area: Float
    $built_area: Float
    $floor: Int
    $bedrooms: Int
    $bathrooms: String
    $vulnerable: Boolean
    $price: Float
    $id_crm_movendo: String
    $base_subsidies: Float
    $savings: Float
    $bonus_integration: Float
    $bonus_recruitment: Float
  ) {
    editProperty(
      property_id: $property_id
      identifier: $identifier
      model: $model
      tower: $tower
      lots: $lots
      total_area: $total_area
      municipal_area: $municipal_area
      terrace_area: $terrace_area
      built_area: $built_area
      floor: $floor
      bedrooms: $bedrooms
      bathrooms: $bathrooms
      vulnerable: $vulnerable
      price: $price
      id_crm_movendo: $id_crm_movendo
      base_subsidies: $base_subsidies
      savings: $savings
      bonus_integration: $bonus_integration
      bonus_recruitment: $bonus_recruitment
    ) {
      id
      identifier
      model
      price
      floor
      bedrooms
      bathrooms
      tower
      bookeable
      promisable
      total_area
      municipal_area
      built_area
      terrace_area
      visible
      vulnerable
      available
      id_crm_movendo
      base_subsidies
      savings
      bonus_integration
      bonus_recruitment
      created_at
      medias {
        id
      }
      project {
        id
        name
        description
        slug
        address
        latitude
        longitude
        type_property
        visible
        commune {
          id
        }
      }
      complements {
        id
        warehouse {
          id
          identifier
          price
        }
        parkinglot {
          id
          identifier
          price
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`
