import graphqlClient from '@/plugins/apollo'
import { FETCH_NEWS, EDIT_NEWS, EXPORT_NEWS, CREATE_NEWS, CHANGE_NEWS } from '@/services/graphql/news'

export async function fetchNews(context, filter) {
    if (filter != undefined) {
        var status = "";
        if (filter.status != "" && filter.status != null) { status = filter.status }

        var page = "";
        if (filter.page != "") { page = filter.page }

        var title = "";
        if (filter.title != "") { title = filter.title }

        var date = "";
        if (filter.date != "") { date = filter.date }
    }
    return new Promise((resolve, reject) => {
        var variables = {
            title: title,
            date: date,
            status: status,
            page: page
        };
        graphqlClient.query({
            query: FETCH_NEWS,
            variables: variables
        }).then(res => {
            context.commit('SET_NEWS', res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function editNews(context, preapproval) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EDIT_NEWS,
            context: {
                hasUpload: true // Important!
            },
            variables: {
                preapproval_id: preapproval.id,
                status: preapproval.status,
                description: preapproval.description,
                file: preapproval.file,
            },
            update: (store, { data: { EDIT_NEWS } }) => {
                const data = store.readQuery({ query: FETCH_NEWS, variables: { page: 1 } })
                context.commit('SET_NEWS', res.data)
                store.writeQuery({ query: FETCH_NEWS, data })
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportNews(context, filters) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_NEWS,
            variables: {
                project_id: filters.project,
                status: filters.status,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function changeVisible(context, news) {
    return new Promise((resolve, reject) => {
        let data = {
            new_id: news.id,
            status: news.status
        };
        graphqlClient.mutate({
            mutation: CHANGE_NEWS,
            variables: data,
            update: (store, { data: { CHANGE_NEWS } }) => {
                // const data = store.readQuery({query: FETCH_AGENCIES,
                // variables: { page: 1 }})
                // context.commit('SET_AGENCIES',data)
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function createNews(context, news) {
    return new Promise((resolve, reject) => {
        let data = {
            title: news.title,
            body: news.body,
            date: news.date,
            user_id: news.user_id,
            status: news.status,
            subtitle: news.subtitle,
            source: news.source,
            body_footer: news.body_footer,
        };
        if (news.logo) {
            if (news.logo instanceof File) {
                data.logo = news.logo;
            }
        }
        if (news.cover_page) {
            if (news.cover_page instanceof File) {
                data.cover_page = news.cover_page;
            }
        }
        graphqlClient.mutate({
            mutation: CREATE_NEWS,
            context: {
                hasUpload: true // Important!
            },
            variables: data,
            update: (store, { data: { createNews } }) => {
                // const data = store.readQuery({query: FETCH_AGENCIES, variables: {page: 1}})
                // data.realstateagencies.data.unshift(createRealStateAgency)
                // store.writeQuery({query: FETCH_AGENCIES, data})
            },
        }).then(res => {
            context.commit('ADD_ONE_NEWS', res.data);
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}
export async function updateNews(context, news) {
    return new Promise((resolve, reject) => {
        let data = {
            news_id: news.id,
            title: news.title,
            body: news.body,
            date: news.date,
            user_id: news.user_id,
            status: news.status,
            subtitle: news.subtitle,
            source: news.source,
            body_footer: news.body_footer,
            tagsNews: news.tagsNews,
            projectsNews: news.projectsNews,
            video_youtube_id: news.video_youtube_id

        };
        if (news.logo) {
            if (news.logo instanceof File) {
                data.logo = news.logo;
            }
        }
        if (news.cover_page) {
            if (news.cover_page instanceof File) {
                data.cover_page = news.cover_page;
            }
        }
        graphqlClient.mutate({
            mutation: EDIT_NEWS,
            variables: data,
            update: (store, { data: { EDIT_NEWS } }) => {
                // const data = store.readQuery({query: FETCH_AGENCIES})
                // context.commit('SET_AGENCIES',data)
                // store.writeQuery({query: FETCH_AGENCIES, data})
                // const data = store.readQuery({query: FETCH_AGENCIES,
                // variables: { page: 1 }})
                // context.commit('SET_AGENCIES',data) 
            },
        }).then(res => {
            context.commit('UPDATE_ONE_NEWS', res.data);
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}